import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import ICU from 'i18next-icu';
import resourcesToBackend from 'i18next-resources-to-backend';
import { initReactI18next } from 'react-i18next';

import { AVAILABLE_LANGUAGES, type AvailableLanguage } from '@/constants/i18n';
import '@/i18n/dayjs';
import dayjs from 'dayjs';

// Phrase requires `id-id` and `de-de` as the locale code (https://support.phrase.com/hc/en-us/articles/5818281650204-Languages-and-Locales-Strings),
// and Auth0 requires `id` and `de` (https://auth0.com/docs/customize/internationalization-and-localization/universal-login-internationalization).
// To satisfy both requirements, we would set `id` as one of the app language while we import from `id-id` that's created by Phrase.

// i18next expects the short locale code (e.g. `id`) as the language code, so we need to map the language code to the locale code when importing the translation files.
const IMPORT_SHORT_LOCALE_MAP: {
  [key in Extract<AvailableLanguage, 'id' | 'de'>]: string;
} = {
  id: 'id-id',
  de: 'de-de',
} as const;

export const icu = new ICU({
  bindI18n: 'languageChanged',
  parseLngForICU: (lng) => {
    return lng === 'en' ? 'en-GB' : lng;
  },
  memoize: true,
});

i18n
  .use(
    resourcesToBackend((language: AvailableLanguage, namespace: any) => {
      const requestedLocale =
        IMPORT_SHORT_LOCALE_MAP[
          language as keyof typeof IMPORT_SHORT_LOCALE_MAP
        ] ?? language;
      return import(`./${requestedLocale}/${namespace}.json`);
    }),
  )
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(icu)
  .init({
    fallbackLng: 'en',
    defaultNS: 'common',
    fallbackNS: 'common',
    supportedLngs: AVAILABLE_LANGUAGES,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    react: {
      useSuspense: false,
    },
    keySeparator: '.',
    detection: {
      order: ['path', 'localStorage', 'navigator'],
    },
  })
  .then(() => {
    dayjs.locale(i18n.language?.toLowerCase() ?? 'en');
  });

const ICU_CONFIGS = {
  'zh-HK': {
    number: {},
  },
  'pt-BR': {
    number: {},
  },
  id: {
    number: {},
  },
  en: {
    number: {},
  },
  'zh-CN': {
    number: {},
  },
  de: {
    number: {},
  },
  it: {
    number: {},
  },
} as const;

export const getICUConfig = (lng: string) => {
  return (
    ICU_CONFIGS[lng as keyof typeof ICU_CONFIGS] ||
    ICU_CONFIGS['en' as keyof typeof ICU_CONFIGS]
  );
};

icu.addUserDefinedFormats(getICUConfig(i18n.language));

export default i18n;
