import { EMPTY_CELL_VALUE } from '@/constants/table';
import { i18n } from '@/i18n';
import currencyData from '@/i18n/currency-data.json';
import { formatNumber } from '@/i18n/number/formatNumber';
import { getCurrencySymbolPosition } from '@/i18n/number/getCurrencySymbolPosition';

export const formatCurrency = (
  amount: number,
  currency: string | false | null = 'HKD',
  options: Omit<Intl.NumberFormatOptions, 'style' | 'currency'> = {},
) => {
  const {
    minimumFractionDigits = 2,
    maximumFractionDigits = 2,
    currencyDisplay = 'symbol',
    ...rest
  } = options;

  const opts = {
    minimumFractionDigits,
    maximumFractionDigits,
    currencyDisplay,
    ...rest,
  };

  const _currency = currency ? currency.toUpperCase() : 'HKD';

  if (_currency in currencyData && currencyDisplay === 'symbol') {
    const direction = getCurrencySymbolPosition(i18n.language);
    const formattedNumber = formatNumber(amount, {
      ...opts,
      mode: 'parts',
    });
    const formattedNumberInParts = formattedNumber;

    let result = '-';

    if (direction === 'suffix') {
      result =
        currencyData[_currency as keyof typeof currencyData]
          .symbolSuffixTemplate;
    }

    if (direction === 'prefix') {
      result =
        currencyData[_currency as keyof typeof currencyData]
          .symbolPrefixTemplate;
    }

    const {
      sign,
      amount: _amount,
      isNaN,
    } = formattedNumberInParts.reduce(
      (acc, nextVal) => {
        switch (nextVal.type) {
          case 'minusSign':
          case 'plusSign': {
            acc.sign = nextVal.value;
            break;
          }
          case 'nan': {
            acc.isNaN = true;
            break;
          }
          default: {
            acc.amount += nextVal.value;
            break;
          }
        }
        return acc;
      },
      {
        sign: undefined,
        amount: '',
        isNaN: false,
      } as {
        sign: string | undefined;
        amount: string;
        isNaN: boolean;
      },
    );

    if (sign) {
      result = result.replace('{sign}', sign);
    } else {
      result = result.replace('{sign}', '');
    }

    result = result.replace('{amount}', isNaN ? EMPTY_CELL_VALUE : _amount);

    return result;
  }

  // fallback to the web Intl API
  return formatNumber(amount, {
    ...opts,
    currency: _currency,
    style: 'currency',
    currencyDisplay: 'code',
  });
};
