export function getCurrencySymbolPosition(locale: string) {
  const formatter = Intl.NumberFormat(locale, {
    style: 'currency',
    currency: 'USD',
    currencyDisplay: 'symbol',
  });

  const parts = formatter.formatToParts(1);
  const currencySymbolIndex = parts.findIndex(
    (part) => part.type === 'currency',
  );

  if (currencySymbolIndex === 0) {
    return 'prefix' as const;
  } else if (currencySymbolIndex === parts.length - 1) {
    return 'suffix' as const;
  } else {
    return 'unknown' as const;
  }
}
