export const EMPTY_CELL_VALUE = '–';

export type TableColumnSizeOptions = 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl';

export const SORTABLE_COLUMNS = {
  name: 'displayname',
  updatedAt: 'updatedat',
  createdAt: 'createdat',
  // response from API
  LastContactFromCustomers: 'lastcontactfromcustomers',
  LastContact: 'lastcontact',
} as const;

export type SortableColumns = keyof typeof SORTABLE_COLUMNS;
