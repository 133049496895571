import { EMPTY_CELL_VALUE } from '@/constants/table';

import currencyData from '../currency-data.json';

export function getCurrencySymbol(isoCode: string) {
  const _isoCode = isoCode?.toUpperCase();
  if (_isoCode in currencyData) {
    return currencyData[_isoCode as keyof typeof currencyData].symbol;
  }
  return EMPTY_CELL_VALUE;
}
