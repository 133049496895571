import { i18n } from '@/i18n';

export function formatNumber<T extends 'string' | 'parts' = 'string'>(
  amount: number | undefined,
  options?: Intl.NumberFormatOptions & {
    mode?: T;
  },
): T extends 'parts' ? Intl.NumberFormatPart[] : string;
export function formatNumber(
  amount: number | undefined,
  options: Intl.NumberFormatOptions & {
    mode?: 'string' | 'parts';
  } = {},
) {
  if (typeof amount !== 'number') {
    return '-';
  }
  const {
    minimumIntegerDigits = 1,
    minimumFractionDigits = 0,
    maximumFractionDigits = 0,
    mode = 'string',
    ...rest
  } = options;

  const formatOptions: Intl.NumberFormatOptions = {
    minimumIntegerDigits,
    minimumFractionDigits,
    maximumFractionDigits,
    ...rest,
  };

  const isChinese = i18n.language.includes('zh');

  if (mode === 'parts') {
    return Intl.NumberFormat(
      // Fallback to EN for any ZH locales to prevent showing chinese characters
      isChinese ? 'en-US' : i18n.language,
      formatOptions,
    ).formatToParts(amount);
  }

  if (mode === 'string') {
    return Intl.NumberFormat(
      // Fallback to EN for any ZH locales to prevent showing chinese characters
      isChinese ? 'en-US' : i18n.language,
      formatOptions,
    ).format(amount);
  }

  throw new Error('format number unknown mode');
}
